import captureSentryError from '@/utils/CaptureSentryError.js'
import integrations from '@/third-party-integrations/integrations.js'
import sdk from '@megaport/api-sdk'

// Initial state
const coreState = {
  usersReady: false,
  users: [],
}

const getters = {
  getUser: state => employmentId => {
    return state.users.find(user => user.employmentId === employmentId)
  },
  getUserByPersonUid: state => personUid => {
    return state.users.find(user => user.personUid === personUid)
  },
}

const actions = {
  syncUsers(context) {
    return new Promise((resolve, reject) => {
      sdk.instance
        .employment()
        .get()
        .then(res => {
          // sort by name, then email
          res.sort((a, b) => {
            const aName = [a.firstName, a.lastName, a.email].join('').toLowerCase()
            const bName = [b.firstName, b.lastName, b.email].join('')
            return aName > bName ? 1 : -1
          })

          context.commit('setUsers', res)
          resolve()
        })
        .catch(e => {
          captureSentryError(e)
          reject()
        })
        .finally(() => {
          context.commit('setUsersReady', true)
        })
    })
  },
  postUser(context, payload) {
    return new Promise((resolve, reject) => {
      if (payload.personId) {
        sdk.instance
          .employee(payload.personId)
          .update(payload)
          .then(() => {
            context.dispatch('syncUsers').then(() => {
              context.commit(
                'Notifications/notifyGood',
                {
                  title: window.mpApp.$t('general.success-updating', { thing: window.mpApp.$t('general.user') }),
                  message: window.mpApp.$t('general.updated-thing', { thing: `${payload.firstName} ${payload.lastName}` }),
                },
                {
                  root: true,
                }
              )
              resolve()
            })
          })
          .catch(err => {
            reject(err)
            // TODO: Improve error processing
            context.commit(
              'Notifications/notifyBad',
              {
                title: window.mpApp.$t('general.error-updating', { thing: window.mpApp.$t('general.user') }),
                message: err.data.message,
              },
              {
                root: true,
              }
            )
          })
      } else {
        sdk.instance
          .employment()
          .employ(payload)
          .then(() => {
            context.dispatch('syncUsers').then(() => {
              context.commit(
                'Notifications/notifyGood',
                {
                  title: window.mpApp.$t('general.success-creating', { thing: window.mpApp.$t('general.user') }),
                  message: window.mpApp.$t('general.created-thing', { thing: `${payload.firstName} ${payload.lastName}` }),
                },
                {
                  root: true,
                }
              )
              resolve()
            })
          })
          .catch(err => {
            reject(err)

            if (!err.data) {
              const error = new Error('Failed to create employee')
              integrations.sentry.captureException(error, { 
                contexts: {
                  company: {
                    payload: payload,
                    error: err,
                    'X-Call-Context': context.rootGetters['ApplicationContext/companyUid'],
                  },
                },
              })
            }
            context.commit(
              'Notifications/notifyBad',
              {
                title: window.mpApp.$t('general.error-creating', { thing: window.mpApp.$t('general.user') }),
                message: err.data?.message || window.mpApp.$t('general.no-info'),
              },
              {
                root: true,
              }
            )
          })
      }
    })
  },
  deleteUser(context, payload) {
    return new Promise((resolve, reject) => {
      if (payload.personId) {
        sdk.instance
          .employee(payload.personId)
          .delete()
          .then(() => {
            context.dispatch('syncUsers').then(() => {
              context.commit(
                'Notifications/notifyGood',
                {
                  title: window.mpApp.$t('general.success-deleting', { thing: window.mpApp.$t('general.user') }),
                  message: window.mpApp.$t('general.deleted-thing', { thing: `${payload.firstName} ${payload.lastName}` }),
                },
                { root: true }
              )
              resolve()
            })
          })
          .catch(err => {
            reject(err)
            context.commit(
              'Notifications/notifyBad',
              {
                title: window.mpApp.$t('general.error-deleting', { thing: window.mpApp.$t('general.user') }),
                message: err.data.message,
              },
              { root: true }
            )
          })
      }
    })
  },
  updateLoggingServices(_context, payload) {
    // Set user information in third party integrations on login
    if (payload.status === 'login') {
      // Sentry
      integrations.sentry.setUser(payload)
      // PostHog
      integrations.postHog.setUser(payload)
      // Segment
      integrations.segment.setUser(payload)
      integrations.segment.trackEvent('login')

    // Clear user information on logout
    } else if (payload.status === 'logout') {
      // Sentry
      integrations.sentry.clearUser()
      // PostHog
      integrations.postHog.clearUser()
      // Segment
      integrations.segment.clearUser()
    }
  },
}

const mutations = {
  logout(state) {
    state.users = []
    // TODO: temporary for managing uids for api calls
    sdk.instance.effectiveUid = null
  },
  setUsers(state, payload) {
    state.users = payload
  },
  setUsersReady(state, tf) {
    state.usersReady = tf
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters,
  actions,
  mutations,
}
